import Button from '@/components/Button/Button';
import { Input } from '@/components/FileInput';
import {
  FETCH_STATUS,
  WS_EVENTS,
  WebSocketContext,
} from '@/providers/WebSocketProvider';
import { Grey } from '@/styles/Colors';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { v4 as uuid } from 'uuid';

import { upload } from '../../../api';
import useAxios from '../hooks/useAxios';
import { useLog } from '../hooks/useLog/useLog';
import { useDataContext } from '../providers/DataContextProvider';
import { currentPlaybackAtom } from '../stores/atoms/audio';
import { isShowTimelineAtom } from '../stores/atoms/ui';
import { Music, MusicFile } from '../stores/project';
import { useMusic } from '../stores/recoilHooks/useMusic';
import { useMusicFile } from '../stores/recoilHooks/useMusicFile';
import { useToggleTimeline } from '../stores/recoilHooks/useToggles';
import StyledMusicTab from '../styles/StyledMusicTab';
import MusicListItem from './MusicListItem';

interface ResourceData {
  job_id: string;
  resource_id: string;
}

const MusicTab = () => {
  const { t } = useTranslation();
  const { sessionId, on, off } = useContext(WebSocketContext);
  const isShowTimeline = useRecoilValue(isShowTimelineAtom);
  const { toggleTimeline } = useToggleTimeline();
  const currentPlayback = useRecoilValue(currentPlaybackAtom);
  const { musicFileList, addMusicFiles, updateMusicFile, deleteMusicFile } =
    useMusicFile();
  const { addMusic } = useMusic();
  const { activeSceneId, projectInfo } = useDataContext();
  const [selectedMusic, setSelectedMusic] = useState<MusicFile>();
  const { getUploadInfo } = useAxios();

  const addFiles = useCallback(
    async (files: File[]) => {
      const resources = await Promise.all(
        files.map((file) => {
          return getUploadInfo(sessionId, file.name, file.type);
        })
      );
      addMusicFiles(
        resources.map((resource, index) => ({
          id: resource.data.data.resource_id,
          name: files[index].name,
          projectId: projectInfo?.id,
          fetchStatus: 'READY' as FETCH_STATUS,
          isDeletable: true,
          createdAt: new Date().toISOString(),
        }))
      );
      for (let i = 0; i < resources.length; i++) {
        await upload(resources[i].data.data.upload_url, files[i]);
      }
    },
    [projectInfo?.id, sessionId, addMusicFiles, getUploadInfo]
  );

  const { track } = useLog();

  useEffect(() => {
    const onDone = (data: ResourceData) => {
      const file = musicFileList.find((music) => music.id === data.resource_id);
      delete file?.fetchStatus;
      if (!file) return;
      updateMusicFile(file);
      track('UPLOAD_MUSIC', {
        resource_id: data.resource_id,
        file_name: file.name,
        projectId: projectInfo?.id,
        sceneId: activeSceneId,
      });
    };
    const onFailed = (data: ResourceData) => {
      const file = musicFileList.find((music) => music.id === data.resource_id);
      if (!file) return;
      deleteMusicFile(file);
    };
    on(WS_EVENTS.JOB_DONE, onDone);
    on(WS_EVENTS.JOB_FAILED, onFailed);

    return () => {
      off(WS_EVENTS.JOB_DONE, onDone);
      off(WS_EVENTS.JOB_FAILED, onFailed);
    };
  }, [
    on,
    off,
    musicFileList,
    updateMusicFile,
    deleteMusicFile,
    track,
    activeSceneId,
    projectInfo?.id,
  ]);

  useEffect(() => {
    const selected = musicFileList.find(
      (music) => music.id === selectedMusic?.id
    );
    setSelectedMusic(selected);
  }, [musicFileList, selectedMusic]);

  const handleInsertMusic = useCallback(() => {
    if (!selectedMusic) return;
    if (!isShowTimeline) toggleTimeline();
    const item = {
      id: uuid(),
      type: 'music',
      name: selectedMusic.name,
      resource_id: selectedMusic.id,
      sceneId: activeSceneId,
      projectId: projectInfo?.id,
      position: currentPlayback,
    } as Music;
    if (selectedMusic.url) {
      item.url = selectedMusic.url;
    }
    addMusic(item);
  }, [
    selectedMusic,
    addMusic,
    activeSceneId,
    projectInfo?.id,
    currentPlayback,
    isShowTimeline,
    toggleTimeline,
  ]);

  return (
    <StyledMusicTab className="sp-music">
      <h3>Music Files</h3>
      <ul className="music-list">
        {musicFileList.map((musicFile) => (
          <MusicListItem
            key={musicFile.id}
            music={musicFile}
            isSelected={selectedMusic?.id === musicFile.id}
            onSelect={(music) => setSelectedMusic(music)}
          />
        ))}
      </ul>
      <div className="music-btn">
        <Input
          caption={'Import File'}
          className="btn-import"
          addFiles={addFiles}
          accept="audio/*"
        />
        <Button
          size="lg"
          variant="contained"
          color={Grey[800]}
          className="btn-insert"
          disabled={!selectedMusic}
          onClick={handleInsertMusic}
        >
          {t('Insert to Timeline')}
        </Button>
      </div>
    </StyledMusicTab>
  );
};

export default MusicTab;
