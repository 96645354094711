import { WebSocketContext } from '@/providers/WebSocketProvider';
import { fetchAudio, getAudioBuffer } from '@/util/audio';
import { useCallback, useContext } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { v4 as uuid } from 'uuid';

import { voiceFileMapAtom, voiceProfileListAtom } from '../stores/atoms/voice';
import { VOICE_STORAGE_KEY } from '../stores/data/voices';
import { useVoice } from '../stores/recoilHooks/useVoice';
import { Voice } from '../stores/voice';
import { getVoiceStorageId } from '../VoiceLibrary/utils';
import useAxios from './useAxios';

const DEFAULT_SCRIPT =
  'Hello, this is project screenplay. Production by Supertone.';

const useVoiceProfile = () => {
  const { sessionId } = useContext(WebSocketContext);
  const { getVoiceLibrary, createTtsForVoice } = useAxios();
  const setVoiceProfileList = useSetRecoilState(voiceProfileListAtom);
  const [voiceFileMap, setVoiceFileMap] = useRecoilState(voiceFileMapAtom);
  const { getProfileEditInfoList, addProfileEditInfo } = useVoice();
  const { getResourceInfo } = useAxios();

  const loadVoiceProfileList = useCallback(
    async (id: string) => {
      const profiles = await getVoiceLibrary();

      if (!profiles) return [];
      const projectProfileEditInfoList = await getProfileEditInfoList(id);
      const projectVoiceProfileList = profiles.filter((profile) => {
        const editInfo = projectProfileEditInfoList.find(
          (info) => info.profileId === profile.id
        );
        return !!editInfo;
      });

      // 만약 프로젝트에 등록된 voiceProfile이 없다면, 1개를 추가해준다.
      if (!projectVoiceProfileList.length) {
        const item = profiles[0];
        await addProfileEditInfo({
          id: uuid(),
          profileId: item.id,
          projectId: id,
          age: item.voice_age,
          gender: item.voice_gender,
          defaultAge: item.voice_age,
          defaultGender: item.voice_gender,
          selectedVoiceId: item.default_voice_id,
        });
      }
      setVoiceProfileList(profiles);
      return profiles;
    },
    [
      getVoiceLibrary,
      setVoiceProfileList,
      getProfileEditInfoList,
      addProfileEditInfo,
    ]
  );

  const loadVoiceFile = useCallback(
    async (
      voice: Voice,
      params: { age: number; gender: number },
      url?: string
    ) => {
      let voiceResourceMap = JSON.parse(
        sessionStorage.getItem(VOICE_STORAGE_KEY) || '{}'
      );
      const storageId = getVoiceStorageId(voice.id, params.age, params.gender);
      // 이미 voiceFileMap 에 있는 경우 바로 리턴
      if (voiceFileMap[storageId]) {
        return voiceFileMap[storageId];
      } else if (url) {
        const audio = await fetchAudio(url);
        const audioBuffer = await getAudioBuffer(audio.arrayBuffer);
        setVoiceFileMap((prev) => {
          return {
            ...prev,
            [storageId]: {
              audioBuffer: audioBuffer as AudioBuffer,
            },
          };
        });
        return { audioBuffer: audioBuffer as AudioBuffer };
      } else {
        // resourceId를 가지고 있는 경우, 서버에서 가져오기 시작
        if (voiceResourceMap[storageId]) {
          const { data } = await getResourceInfo(voiceResourceMap[storageId]);
          const audio = await fetchAudio(data.data.transcoded[0].url);
          const audioBuffer = await getAudioBuffer(audio.arrayBuffer);
          setVoiceFileMap((prev) => {
            return {
              ...prev,
              [storageId]: {
                audioBuffer: audioBuffer as AudioBuffer,
              },
            };
          });
          return { audioBuffer: audioBuffer as AudioBuffer };
        } else {
          // 아예 처음 불러오는 경우 tts를 통해 가져오기 시작
          const voiceTTS = await createTtsForVoice(
            {
              voice_id: voice.id,
              language: voice.script_language,
              age: params.age,
              gender: params.gender,
            },
            voice.script || DEFAULT_SCRIPT,
            sessionId
          );
          // 가져온 데이터는 audioBuffer로 바로 sessionStorage에 저장
          sessionStorage.setItem(
            VOICE_STORAGE_KEY,
            JSON.stringify({
              ...voiceResourceMap,
              [storageId]: voiceTTS.resource_id,
            })
          );
          // voiceFileMap에도 저장
          setVoiceFileMap((prev) => {
            return {
              ...prev,
              [storageId]: {
                audioBuffer: voiceTTS.audioBuffer as AudioBuffer,
              },
            };
          });
          return { audioBuffer: voiceTTS.audioBuffer as AudioBuffer };
        }
      }
    },
    [
      sessionId,
      setVoiceFileMap,
      voiceFileMap,
      getResourceInfo,
      createTtsForVoice,
    ]
  );
  return {
    sessionId,
    loadVoiceFile,
    loadVoiceProfileList,
  };
};

export default useVoiceProfile;
