import { atom } from 'recoil';

import { QuickMenuType } from '../data/config';
import { VideoPlayerState } from '../video';

export const DEFAULT_TIMELINE_PANEL_HEIGHT = 232;
export const HEADER_HEIGHT = 3.25;

export const isModalOpenAtom = atom<boolean>({
  key: 'isModalOpen',
  default: false,
});

export const isShowTimelineAtom = atom<boolean>({
  key: 'isShowTimeline',
  default: false,
});

export const isShowMediaAtom = atom<boolean>({
  key: 'isShowMedia',
  default: false,
});

export const isShowVoiceLibraryAtom = atom<boolean>({
  key: 'isShowVoiceLibrary',
  default: false,
});

export const isTextEditingAtom = atom<boolean>({
  key: 'isTextEditing',
  default: false,
});

export const timelinePanelHeightAtom = atom<number>({
  key: 'timelinePanelHeight',
  default: DEFAULT_TIMELINE_PANEL_HEIGHT,
});

export const isLoadingProjectAtom = atom<boolean>({
  key: 'isLoadingProject',
  default: false,
});

export const videoPlayerStateAtom = atom<VideoPlayerState | null>({
  key: 'videoPlayerState',
  default: null,
});

export const timelinePlaybackAtom = atom<number>({
  key: 'timelinePlayback',
  default: 0,
});

export const selectedQuickMenuAtom = atom<QuickMenuType>({
  key: 'selectedQuickMenu',
  default: 'Voice',
});

export const isShowFeedbackAtom = atom<boolean>({
  key: 'isShowFeedback',
  default: true,
});

export const selectedLineIdsAtom = atom<string[]>({
  key: 'selectedLineIds',
  default: [],
});
