import Button from '@/components/Button/Button';
import IconButton from '@/components/Button/IconButton';
import Loading from '@/components/Loading/Loading';
import Waveform from '@/components/Waveform/Waveform';
import { Grey } from '@/styles/Colors';
import { fetchAudio, getAudioBuffer } from '@/util/audio';
import { formatSToMMSS } from '@/util/formatter';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { ReactComponent as DeleteBlockIcon } from '../assets/icons/DeleteBlockIcon.svg';
import { ReactComponent as LinePauseIcon } from '../assets/icons/LinePauseIcon.svg';
import { ReactComponent as LinePlayIcon } from '../assets/icons/LinePlayIcon.svg';
import useAxios from '../hooks/useAxios';
import useSingleAudioController from '../hooks/useSingleAudioController';
import { audioFileMapAtom, audioPlayerStateAtom } from '../stores/atoms/audio';
import { MusicFile } from '../stores/project';
import { useMusic } from '../stores/recoilHooks/useMusic';
import { useMusicFile } from '../stores/recoilHooks/useMusicFile';

interface MusicListItemProps {
  music: MusicFile;
  isSelected?: boolean;
  onSelect: (music: MusicFile) => void;
}

const MusicListItem = ({ music, isSelected, onSelect }: MusicListItemProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [audioFileMap, setAudioFileMap] = useRecoilState(audioFileMapAtom);
  const [audioPlayerState, setAudioPlayerState] =
    useRecoilState(audioPlayerStateAtom);
  const { musicList } = useMusic();
  const { deleteMusicFile } = useMusicFile();
  const { load, audioBuffer, isPlaying, play, pause, stop, currentPosition } =
    useSingleAudioController();
  const { getResourceInfo } = useAxios();

  const fetchMusic = useCallback(async () => {
    if (audioFileMap[music.id]) {
      load(audioFileMap[music.id].audioBuffer);
      setIsLoading(false);
      return;
    }
    setIsLoading(true);

    // timeline에서 쓰이는 파일은 filter하고, 그 외 파일만 fetch
    const isMusicExist = musicList.some(
      (item) => item.resource_id === music.id
    );
    if (isMusicExist) return;

    let url = music.url;
    if (!url) {
      const { data } = await getResourceInfo(music.id);
      url = data.data.transcoded[0].url;
    }

    if (url) {
      const audio = await fetchAudio(url);
      const audioBuffer = (await getAudioBuffer(
        audio.arrayBuffer
      )) as AudioBuffer;

      setAudioFileMap((prev) => ({
        ...prev,
        [music.id]: { audioBuffer, fileName: music.name },
      }));
      load(audioBuffer);
      setIsLoading(false);
    }
  }, [audioFileMap, music, load, setAudioFileMap, musicList, getResourceInfo]);

  useEffect(() => {
    if (audioBuffer || music.fetchStatus) return;
    fetchMusic();
  }, [fetchMusic, audioBuffer, music.fetchStatus]);

  const handlePlayPause = useCallback(
    (time?: number) => {
      if (isPlaying && typeof time !== 'number') {
        pause();
      } else {
        play(time);
      }
      setAudioPlayerState({
        type: 'standalone',
        voiceId: music.id,
        isPlaying: !isPlaying,
      });
      return () => {
        stop();
      };
    },
    [isPlaying, play, pause, stop, setAudioPlayerState, music.id]
  );

  useEffect(() => {
    if (!audioPlayerState || !isPlaying) return;
    if (
      audioPlayerState.type === 'timeline' ||
      (audioPlayerState.type === 'standalone' &&
        audioPlayerState.voiceId !== music.id &&
        audioPlayerState.isPlaying)
    ) {
      requestAnimationFrame(() => {
        stop();
      });
    }
  }, [audioPlayerState, music.id, stop, isPlaying]);

  const handleClick = useCallback(() => {
    if (isLoading) return;
    onSelect(music);
  }, [onSelect, music, isLoading]);

  const handleCancel = useCallback(() => {
    deleteMusicFile(music);
  }, [deleteMusicFile, music]);

  return (
    <li
      className={classNames(
        'music-item',
        { loading: !!music.fetchStatus || isLoading },
        { active: isSelected }
      )}
      onClick={handleClick}
    >
      {!isLoading && !music.fetchStatus && audioBuffer ? (
        <>
          {music.isDeletable && (
            <IconButton
              className="btn-delete"
              variant="none"
              color={Grey[500]}
              onClick={(e) => {
                e.stopPropagation();
                deleteMusicFile(music);
              }}
            >
              <DeleteBlockIcon />
            </IconButton>
          )}
          <button
            disabled={!audioBuffer}
            className={classNames('btn-music-item', {
              play: !isPlaying,
              pause: isPlaying,
            })}
            onClick={() => handlePlayPause()}
          >
            {isPlaying ? <LinePauseIcon /> : <LinePlayIcon />}
          </button>
          <div className="music-title">
            <h3>{music.name}</h3>
            <span>{t('Background music')}</span>
          </div>
          <p className="music-duration">
            {formatSToMMSS(audioBuffer.duration)}
          </p>
          <Waveform
            audioBuffer={audioBuffer}
            playback={currentPosition}
            onPlaybackChange={handlePlayPause}
            progressColor={Grey[300]}
            waveColor={Grey[500]}
            cursorColor="transparent"
            waveRectWidth={2}
          />
        </>
      ) : (
        <>
          <Loading className="sup-loading" />
          <span>
            {!music.fetchStatus
              ? 'Loading'
              : music.fetchStatus === 'FAILED'
              ? 'Failed'
              : 'Uploading'}
          </span>
          {music.fetchStatus && music.fetchStatus !== 'FAILED' && (
            <Button
              size="sm"
              variant="outlined"
              color={Grey[50]}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          )}
        </>
      )}
    </li>
  );
};

export default MusicListItem;
