import { Black, Grey, Secondary } from '@/styles/Colors';
import { FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';
export const StyledTakeItem = styled.section`
  /* {Take Item css} */
  &.line-take-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid ${Black};
    height: 3.25rem;
    line-height: 1rem;
    box-sizing: border-box;
    &:hover {
      border: 1px solid ${Grey[500]};
    }
    .line-take-number {
      width: 7.75rem;
      color: ${Grey[300]};
      font-weight: ${FontWeight['SemiBold']};
    }
    .line-take-play {
      width: 3.25rem;
      height: 3.25rem;
      .btn-take-play,
      .btn-take-stop {
        width: 3.25rem;
        height: 3.25rem;
      }
      .btn-take-play {
        svg {
          width: 1.7rem;
          height: 1.7rem;
        }
        &:hover {
          svg {
            fill: ${Grey[300]};
          }
        }
      }
      .btn-take-stop {
        svg {
          width: 1.7rem;
          height: 1.7rem;
        }
      }
    }
    .line-take-waveform {
      width: 100%;
      height: calc(3.25rem - 2px);
      display: flex;
      align-items: center;
    }
    &:hover {
      .line-take-number {
        color: ${Grey[50]};
      }
    }
    &.selected {
      background-color: ${Grey[600]} !important;
      .line-take-select {
        color: #f4f4f4;
        background-color: ${Grey[600]};
      }
      .line-take-number {
        color: ${Grey[50]};
      }
    }
    .line-take-select {
      padding-right: 1.05rem;
      padding-left: 1.05rem;
      font-size: 1rem;
      font-weight: 500;
      background-color: ${Grey[800]};
      color: ${Grey[500]};
      .btn-take-select {
        width: 1.5rem;
        height: 1.5rem;
        border: 0;
        background-color: transparent;
        &.unSelected {
          width: 1.25rem;
          height: 1.25rem;
          margin-right: 0.25rem;
          background-color: transparent;
        }
      }
    }
    .line-take-text {
      width: 100%;
    }
    &.cvc {
      .line-take-number {
        color: ${Secondary[300]};
      }
    }
  }
  .fetching-status {
    display: none;
    .loader {
      position: relative;
    }
  }
  &.fetching {
    position: relative;
    .line-take-select,
    .line-takes-content {
      display: none !important;
    }
    .fetching-status {
      visibility: visible !important;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3rem;
    }
  }
`;
