import { Grey } from '@/styles/Colors';
import { FontSize, FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

const StyledMusicTab = styled.section`
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  h3 {
    padding: 1.8rem 1.5rem 1.3rem;
    font-size: ${FontSize['2Xl']};
    font-weight: ${FontWeight['SemiBold']};
    line-height: 1.5;
    max-width: 10rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .music-list {
    flex-grow: 1;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 1.3rem;
      background-color: transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-clip: padding-box;
      border: 0.5rem solid rgba(0, 0, 0, 0);
      border-radius: 4.5rem;
      background-color: rgba(217, 217, 217, 0.2);
      box-shadow: inset 0 0 0 0.75px ${Grey[400]};
    }
    .music-item {
      position: relative;
      cursor: pointer;
      width: 100%;
      height: 6.75rem;
      padding: 0.75rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.5rem;
      background: ${Grey[600]};
      &:hover {
        background: ${Grey[700]};
      }
      &.active {
        background: ${Grey[800]};
      }
      &:hover,
      &.active {
        .play {
          rect {
            stroke: ${Grey[50]};
          }
          path {
            fill: ${Grey[50]};
          }
        }
        .pause {
          rect:first-of-type {
            stroke: ${Grey[50]};
          }
          rect + rect {
            fill: ${Grey[50]};
          }
        }
        .btn-delete {
          display: block;
        }
      }
      .btn-delete {
        position: absolute;
        top: 0.34rem;
        right: 0.27rem;
        display: none;
      }
      .btn-music-item {
        background: none;
        border: none;
        outline: none;
        padding: 0;
        cursor: pointer;
        width: 3.25rem;
        height: 3.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .play {
        padding: 0.75rem;
        rect {
          stroke: ${Grey[400]};
        }
        path {
          fill: ${Grey[400]};
        }
      }
      .pause {
        padding: 0.75rem;
        rect:first-of-type {
          stroke: ${Grey[400]};
        }
        rect + rect {
          fill: ${Grey[400]};
        }
      }
      .music-title {
        flex-grow: 1;
        h3 {
          padding: 0;
          font-size: ${FontSize['Lg']};
          font-weight: ${FontWeight['SemiBold']};
          line-height: 1.5;
        }
        span {
          font-size: ${FontSize['Lg']};
          line-height: 1.5;
          color: ${Grey[400]};
        }
      }
      .music-duration {
        width: 2.5rem;
        font-size: ${FontSize['Md']};
        font-weight: ${FontWeight['SemiBold']};
        line-height: 1.5;
      }
      .sup-waveform {
        margin-left: 0.75rem;
        margin-right: 0.75rem;
        border: none;
        background: none;
        width: 100%;
        height: 1.25rem;
        align-self: center;
      }
      &.loading {
        cursor: not-allowed;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        .sup-loading {
          left: calc(50% - 4.5rem);
          transform: translateX(-50%);
        }
        .sup-button {
          position: absolute;
          top: 50%;
          right: 1.25rem;
          transform: translateY(-50%);
          border-radius: 12.5rem;
          font-size: ${FontSize['Md']};
          font-weight: ${FontWeight['SemiBold']};
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }
  .music-btn {
    padding: 1.25rem 1.5rem;
    display: flex;
    flex-flow: column;
    gap: 1.5rem;
    .file-add-button,
    .sup-button {
      padding: 0.38rem 0.75rem;
      flex-grow: 1;
      border-radius: 0.125rem;
      font-size: 1rem;
    }
    .file-add-button {
      justify-content: center;
      background: none;
      border: 1px solid ${Grey[50]};
      color: ${Grey[50]};
    }
  }
`;

export default StyledMusicTab;
