import './index.css';
import './api/index';

import { SUPAuthProvider, SupertoneApps } from '@supertone-inc/auth-sdk-react';
import mixpanel from 'mixpanel-browser';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <SUPAuthProvider
    supertoneApp={SupertoneApps.Screenplay}
    authorizationParams={{
      redirect_uri: window.location.origin,
    }}
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </SUPAuthProvider>
  // </React.StrictMode>
);

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN as string);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
