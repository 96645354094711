import { Grey, PureBlack } from '@/styles/Colors';
import styled from '@emotion/styled';

export const StyledUserInfo = styled.section`
  .initial {
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    background-color: ${Grey[300]};
    text-align: center;
    line-height: 150%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: ${PureBlack};
    font-size: 1.25rem;
  }
`;

export const StyledUserLayer = styled.div`
  padding: 1rem 0.75rem 0.5rem;
  color: ${Grey[50]};
  z-index: 100;
  .info {
    text-align: center;
    white-space: nowrap;
    display: block;
    margin-bottom: 1rem;
    line-height: 100%;
  }
  .logout {
    width: 100%;
    min-width: 9.25rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-weight: bold;
  }
`;
