import { useCallback, useEffect, useRef, useState } from 'react';

import { useSceneEditorContext } from '../providers/SceneEditorContextProvider';
import { Scene } from '../stores/project';

const SceneName: React.FC<{ scene: Scene }> = ({ scene }) => {
  const [editingSceneId, setEditingSceneId] = useState<string>('');
  const { updateScene } = useSceneEditorContext();

  const onDoubleClick = useCallback(() => {
    setEditingSceneId(scene.id);
  }, [setEditingSceneId, scene.id]);

  const changeName = useCallback(
    (value: string) => {
      if (!value.trim()) return;
      updateScene({ ...scene, name: value });
    },
    [updateScene, scene]
  );

  const onBlur = useCallback(() => {
    setEditingSceneId('');
  }, [setEditingSceneId]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.code === 'Enter') {
        e.preventDefault();
        changeName(e.currentTarget.value);
        setEditingSceneId('');
      }
      if (e.code === 'Escape') {
        setEditingSceneId('');
      }
    },
    [setEditingSceneId, changeName]
  );

  const ref = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (editingSceneId === scene.id) {
      ref.current?.focus();
    }
  }, [editingSceneId, scene.id]);

  return editingSceneId !== scene.id ? (
    <strong className="list-item-title" onDoubleClick={onDoubleClick}>
      {scene.name}
    </strong>
  ) : (
    <strong className="list-item-title editing">
      <textarea
        ref={ref}
        rows={1}
        className="list-item-title-edit"
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        placeholder={scene.name}
        data-hj-allow
      />
    </strong>
  );
};
export default SceneName;
