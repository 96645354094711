import { Black, Grey, Primary, White } from '@/styles/Colors';
import { FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

import SearchIcon from '../assets/icons/SearchIcon.svg';

const StyledVoiceLibrary = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  overflow: auto;
  height: 100%;
  min-height: 78rem; /* 65rem * 1.2 */
  display: flex;
  justify-content: center;
  .overlay-layer {
    background: rgba(0, 0, 0, 0.75);
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
  .voice-library-container {
    flex-direction: column;
    width: 95rem; /* 75rem * 1.2 */
    display: flex;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(100% - 24rem); /* 20rem * 1.2 */
    overflow-y: auto;
    .voice-library-header {
      position: relative;
      background-color: ${Grey[600]};
      height: 3rem; /* 2.5rem * 1.2 */
      min-height: 3rem; /* 2.5rem * 1.2 */
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.75rem 0.75rem 0 0; /* 0.625rem * 1.2 */
      .voice-library-header-title {
        color: ${White};
        font-size: 1.2rem; /* 1rem * 1.2 */
        font-weight: ${FontWeight['SemiBold']};
      }
    }
    .voice-library-body {
      display: flex;
      flex: 1;
      overflow: hidden;
    }
  }
  z-index: 2;
  * {
    box-sizing: border-box;
  }
  & > .sup-title {
    background: ${Grey[800]};
    width: 26.4rem; /* 22rem * 1.2 */
    padding: 1.5rem 1.56rem; /* 1.25rem, 1.3rem * 1.2 */
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
  }
  .btn-close {
    position: absolute;
    top: 0.6rem; /* 0.5rem * 1.2 */
    right: 0.744rem; /* 0.62rem * 1.2 */
    width: 1.8rem; /* 1.5rem * 1.2 */
    height: 1.8rem; /* 1.5rem * 1.2 */
    z-index: 1;
  }
  .voice-library-filter,
  .voice-library-items,
  .intro-voice p {
    &::-webkit-scrollbar {
      width: 15.6px; /* 13px * 1.2 */
      background-color: transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-clip: padding-box;
      border: 4.8px solid transparent; /* 4px * 1.2 */
      border-radius: 5.4rem; /* 4.5rem * 1.2 */
      background-color: transparent;
      box-shadow: rgb(118, 118, 118) 0px 0px 0px 0.9px inset; /* 0.75px * 1.2 */
    }
  }
  /* voice filters */
  .voice-library-filter {
    background-color: ${Grey[800]};
    width: 21rem; /* 17.5rem * 1.2 */
    min-width: 21rem; /* 17.5rem * 1.2 */
    border-right: 1.2px solid ${Grey[500]}; /* 1px * 1.2 */
    border-radius: 0 0 0 0.75rem; /* 0.625rem * 1.2 */
    overflow-y: auto;
    .search {
      box-sizing: border-box;
      margin-top: 1.5rem;
      padding: 0 1.5rem; /* 1.25rem * 1.2 */
      width: 21rem; /* 17.5rem * 1.2 */
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      .search-title {
        color: ${Grey[50]};
        font-family: Inter;
        font-size: 1.2rem; /* 1rem * 1.2 */
        font-style: normal;
        font-weight: ${FontWeight['Medium']};
        line-height: 150%;
      }
      .sup-button {
        padding: 0;
        font-weight: ${FontWeight['Bold']};
      }
      .input {
        margin-top: 2rem;
        position: relative;
        width: 100%;
        i {
          position: absolute;
          top: 0.456rem; /* 0.38rem * 1.2 */
          left: 0.456rem; /* 0.38rem * 1.2 */
          width: 2.1rem; /* 1.75rem * 1.2 */
          height: 2.1rem; /* 1.75rem * 1.2 */
          background-image: url(${SearchIcon});
          background-size: cover;
        }
        input {
          box-sizing: border-box;
          padding-left: 3rem; /* 2.5rem * 1.2 */
          width: 100%;
          height: 3rem; /* 2.5rem * 1.2 */
          border: none;
          outline: none;
          &:focus,
          &:active {
            outline: none;
          }
          border-radius: 1.5rem; /* 1.25rem * 1.2 */
          display: block;
          font-size: 0.9rem; /* 0.75rem * 1.2 */
          line-height: 1.8; /* 1.5 * 1.2 */
          background-color: ${Grey[600]};
          color: ${Grey[300]};
          &::placeholder {
            color: ${Grey[300]};
          }
        }
      }
    }
    .clear-all-area {
      width: 100%;
      display: flex;
      justify-content: end;
      .btn-clear-all {
        font-size: 0.9rem; /* 0.75rem * 1.2 */
        margin-top: 0.6rem; /* 0.5rem * 1.2 */
      }
    }
    .filter {
      padding-top: 1.2rem; /* 1rem * 1.2 */
      & > li {
        padding: 0.9rem 1.5rem; /* 0.75rem, 1.25rem * 1.2 */
        display: flex;
        gap: 1.2rem; /* 1rem * 1.2 */
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
        .filter-title {
          font-size: 1.05rem; /* 0.875rem * 1.2 */
          font-weight: ${FontWeight['SemiBold']};
          color: ${Grey[200]};
          flex-grow: 1;
        }
        & > span {
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 0.6rem; /* 0.5rem * 1.2 */
          width: 2.1rem; /* 1.75rem * 1.2 */
          height: 1.8rem; /* 1.5rem * 1.2 */
          background-color: ${Grey[100]};
          color: ${Black};
          border-radius: 0.156rem; /* 0.13rem * 1.2 */
          user-select: none;
        }
        .sup-icon-button {
          width: 2.1rem; /* 1.75rem * 1.2 */
        }
      }
      .filter-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        gap: 0.6rem; /* 0.5rem * 1.2 */
        li {
          margin-top: 0.3rem; /* 0.25rem * 1.2 */
          display: flex;
          align-items: center;
          color: ${Grey[500]};
        }
        input[type='checkbox'] {
          display: none;
          &:checked + label {
            background-color: ${Grey[100]};
            color: ${Black};
            font-weight: ${FontWeight['SemiBold']};
            text-align: left;
          }
        }
      }
      label {
        box-sizing: border-box;
        padding: 0 1.32rem; /* 1.1rem * 1.2 */
        height: 2.1rem; /* 1.75rem * 1.2 */
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.48rem; /* 0.4rem * 1.2 */
        border-radius: 0.156rem; /* 0.13rem * 1.2 */
        border: 1.2px solid ${Grey[500]}; /* 1px * 1.2 */
        user-select: none;
        cursor: pointer;
        text-transform: capitalize;
        text-align: center;
        border-radius: 1.5rem; /* 1.25rem * 1.2 */
        font-size: 0.9rem; /* 0.75rem * 1.2 */
        span {
          width: 0.96rem; /* 0.8rem * 1.2 */
        }
        path {
          fill: ${Black};
        }
      }
    }
  }
  /* voice list */
  .voice-library-list {
    position: relative;
    padding: 1.5rem 1.8rem;
    display: flex;
    flex-direction: column;
    width: 74rem;
    background-color: ${Black};
    overflow: auto;
    border-radius: 0 0 0.75rem 0; /* 0.625rem * 1.2 */
    .voice-library-tab {
      margin-bottom: 1.8rem; /* 1.5rem * 1.2 */
      display: flex;
      gap: 1.2rem; /* 1rem * 1.2 */
      li {
        cursor: pointer;
        .sup-button {
          color: ${Grey[500]};
          font-size: 1.2rem; /* 1rem * 1.2 */
          font-weight: ${FontWeight['SemiBold']};
        }
        &.active {
          .sup-button {
            color: ${White};
          }
        }
      }
    }
    .voice-library-title {
      min-width: 64.8rem; /* 54rem * 1.2 */
      display: grid;
      grid-template-columns: 11.52rem 8.4rem 7.2rem 7.2rem 1fr; /* 8rem, 7rem, 6rem, 6rem * 1.2 */
      gap: 1.5rem; /* 1.25rem * 1.2 */
      padding: 0.9rem 19.2rem 0.9rem 5.04rem; /* 0.75rem, 16rem, 4.2rem * 1.2 */
      background-color: ${Grey[800]};
      align-items: center;
      white-space: nowrap;
      li {
        font-size: 0.975rem; /* 0.8125rem * 1.2 */
        font-weight: ${FontWeight['SemiBold']};
        color: ${Grey[500]};
      }
    }
    .voice-library-items {
      display: flex;
      min-width: 64.8rem; /* 54rem * 1.2 */
      flex-direction: column;
      overflow-y: scroll;
      .voice-library-item {
        background: ${Black};
        border-bottom: 1.2px solid ${Grey[600]}; /* 1px * 1.2 */
        font-size: 0.975rem; /* 0.8125rem * 1.2 */
        &:hover {
          background-color: ${Grey[800]};
        }
        &.open {
          background-color: ${Grey[700]};
        }
        &.disabled {
          opacity: 0.33;
        }
      }
      .item-summary {
        cursor: pointer;
        box-sizing: border-box;
        height: 4.2rem; /* 3.5rem * 1.2 */
        width: 100%;
        display: grid;
        grid-template-columns: 3.84rem 11.7rem 8.7rem 7.5rem 7.5rem 1fr 8.4rem; /* 3.2rem, 8.25rem, 7.25rem, 6.25rem, 6.25rem, 7rem * 1.2 */
        align-items: center;
        white-space: nowrap;
        gap: 1.2rem; /* 1rem * 1.2 */
        color: ${Grey[50]};
        .voice-profile {
          text-align: center;
          .voice-image {
            width: 3rem;
            height: 3rem;
          }
        }
        .cell {
          overflow: hidden;
          text-overflow: ellipsis;
          text-transform: capitalize;
        }
        &.open {
          height: 18.36rem; /* 15.3rem * 1.2 */
          padding-bottom: 2.628rem; /* 2.19rem * 1.2 */
        }
        .voice-control {
          display: flex;
          align-items: center;
          gap: 0.75rem; /* 0.625rem * 1.2 */
          justify-content: left;
          .sup-loading-dots {
            border: none;
          }
          .btn-voice-icon {
            background-color: transparent;
            border: none;
            color: transparent;
            width: 3.9rem; /* 3.25rem * 1.2 */
            height: 3.9rem; /* 3.25rem * 1.2 */
            &:hover {
              color: ${Grey[300]};
            }
            &.btn-open-slider {
              :hover {
                background-color: ${Grey[450]};
              }
            }
          }
        }
        .btn-voice-action {
          box-sizing: border-box;
          border-radius: 0.15rem; /* 0.125rem * 1.2 */
          width: 8.4rem; /* 7rem * 1.2 */
          height: 2.4rem; /* 2rem * 1.2 */
          line-height: 1.8; /* 1.5 * 1.2 */
          font-weight: ${FontWeight['SemiBold']};
          border: none;
          font-size: 0.9rem; /* 0.75rem * 1.2 */
          :hover {
            background-color: ${Primary[200]};
          }
          &.complete {
            border: 1.2px solid ${White}; /* 1px * 1.2 */
            background-color: transparent;
            opacity: 1;
          }
          &:disabled {
            border: 1.2px solid ${Grey[50]}; /* 1px * 1.2 */
            background-color: transparent;
            opacity: 0.5;
            cursor: not-allowed;
          }
          &.btn-reset {
            background-color: transparent;
          }
        }
        .voice-profile-btn {
          margin-left: 0.6rem; /* 0.5rem * 1.2 */
          display: flex;
          align-items: center;
          height: 2.25rem; /* 1.875rem * 1.2 */
        }
      }
      .item-details {
        padding: 2.4rem 1.8rem 1.5rem 4.8rem; /* 2rem, 1.5rem, 1.25rem, 4rem * 1.2 */
        background-color: ${Black};
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 3rem; /* 2.5rem * 1.2 */
        .profile-img {
          display: flex;
          width: 9rem; /* 7.5rem * 1.2 */
          height: 9rem; /* 7.5rem * 1.2 */
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }
        /* 이미지는 왼쪽에 */
        /* p는 우측에 세로로 정렬  */
        .intro-voice {
          width: 13.5rem; /* 11.25rem * 1.2 */
          flex-direction: column;
          align-items: start;
          p {
            font-size: 0.9rem; /* 0.75rem * 1.2 */
            line-height: 1.5;
            color: ${Grey[200]};
            overflow-y: auto;
          }
        }
        .voice-styles {
          width: 29.4rem; /* 24.5rem * 1.2 */
          background-color: ${Black};
          border-radius: 0.6rem; /* 0.5rem * 1.2 */
          .header {
            grid-column: 1 / 3;
            display: flex;
            justify-content: space-between;
            gap: 1.392rem; /* 1.16rem * 1.2 */
            .sup-title {
              flex-grow: 1;
            }
            .sup-button {
              border-radius: 0.156rem; /* 0.13rem * 1.2 */
            }
          }
          .slider-value {
            width: 4.2rem; /* 3.5rem * 1.2 */
            border-radius: 0.075rem; /* 0.0625rem * 1.2 */
            border: 1.2px solid ${Grey[500]}; /* 1px * 1.2 */
            height: 1.35rem; /* 1.125rem * 1.2 */
            color: ${Grey[50]};
            font-weight: ${FontWeight['SemiBold']};
            font-size: 1.05rem; /* 0.875rem * 1.2 */
            text-align: center;
          }
          .slider-control {
            grid-row: 2 / 3;
            height: 8.04rem; /* 6.7rem * 1.2 */
            border-radius: 0.45rem; /* 0.375rem * 1.2 */
            display: flex;
            justify-content: center;
            flex-direction: column;
            gap: 1.38rem; /* 1.15rem * 1.2 */
            padding: 0 1.2rem; /* 1rem * 1.2 */
            &-header {
              display: flex;
              justify-content: space-between;
              .sup-stepper-button {
                display: none;
              }
            }
            .sup-slider {
              .slider-label {
                bottom: 2.4rem; /* 2rem * 1.2 */
                background-color: ${Black};
                z-index: 10;
              }
            }
            .slider-track,
            .slider-thumb {
              box-shadow: none;
            }
            .slider-range::-webkit-slider-thumb:hover {
              box-shadow: none;
              background: none;
            }
            .slider-range:disabled + .slider-track {
              height: 2.4px; /* 2px * 1.2 */
            }
          }
          .btn-group {
            display: flex;
            gap: 1rem;
            flex-wrap: wrap;
          }
          .btn-style {
            background: ${Black};
            padding: 0 0.9rem; /* 0 0.75rem * 1.2 */
            min-width: 9.6rem; /* 8rem * 1.2 */
            border-radius: 2.25rem; /* 1.875rem * 1.2 */
            height: 2.4rem; /* 2rem * 1.2 */
            display: inline-flex;
            gap: 0.3rem; /* 0.25rem * 1.2 */
            align-items: center;
            justify-content: center;
            padding: 0.45rem 0; /* 0.375rem * 1.2 */
            border: 1.2px solid ${Grey[50]}; /* 1px * 1.2 */
            color: ${Grey[50]};
            cursor: pointer;
            position: relative;
            font-size: 1.05rem; /* 0.875rem * 1.2 */
            .sup-loading-dots {
              border: none;
            }
            &:focus,
            &:active {
              outline: none;
            }
            &:hover {
              background-color: ${Grey[50]};
              color: ${Grey[800]};
              border: 1.2px solid ${Grey[50]}; /* 1px * 1.2 */
              .icon {
                display: block;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                  width: 1.35rem; /* 1.125rem * 1.2 */
                  height: 1.35rem; /* 1.125rem * 1.2 */
                }
                path {
                  fill: ${Grey[800]};
                }
              }
            }
            &.active {
              background-color: ${Grey[50]};
              color: ${Grey[800]};
              .icon {
                display: block;
                rect,
                path {
                  fill: ${Grey[800]};
                }
              }
            }
            &:hover,
            &.active {
              .sup-loading-dots {
                & > div {
                  background-color: ${Grey[700]};
                }
              }
            }
            .icon {
              display: none;
              width: 1.35rem; /* 1.125rem * 1.2 */
              height: 1.35rem; /* 1.125rem * 1.2 */
              svg {
                width: 1.35rem; /* 1.125rem * 1.2 */
                height: 1.35rem; /* 1.125rem * 1.2 */
              }
              &.stop {
                width: 1.68rem; /* 1.4rem * 1.2 */
                height: 1.956rem; /* 1.63rem * 1.2 */
                svg {
                  width: 1.956rem; /* 1.63rem * 1.2 */
                  height: 1.956rem; /* 1.63rem * 1.2 */
                }
              }
            }
          }
        }
      }
    }
    .voice-library-empty {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      h3 {
        font-size: 1.2rem;
        font-weight: ${FontWeight['SemiBold']};
        color: ${White};
        margin-bottom: 1.2rem; /* 1rem * 1.2 */
        line-height: 1.5;
      }
      p {
        font-size: 1.2rem;
        font-weight: ${FontWeight['SemiBold']};
        color: ${Grey[500]};
      }
    }
  }
`;

export default StyledVoiceLibrary;
