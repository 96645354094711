import Button from '@/components/Button/Button';
import IconButton from '@/components/Button/IconButton';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as BottomArrow } from '../assets/icons/BottomArrow.svg';
import { ReactComponent as RightArrow } from '../assets/icons/RightArrow.svg';
import { useDataContext } from '../providers/DataContextProvider';
import { useSceneEditorContext } from '../providers/SceneEditorContextProvider';
import StyledSceneTab from '../styles/StyledSceneTab';
import LineItem from './LineItem';
import SceneName from './SceneName';

const SceneList = () => {
  const { t } = useTranslation();
  const { activeSceneId, updateActiveSceneId, updateActiveLineId } =
    useDataContext();
  const { sceneList, lineList, addScene } = useSceneEditorContext();
  const onClickScene = useCallback(
    (sceneId: string) => {
      updateActiveLineId('');
      updateActiveSceneId(sceneId);
    },
    [updateActiveSceneId, updateActiveLineId]
  );
  const [isOpenActiveScene, setIsOpenActiveScene] = useState(true);
  const toggleActiveScene = useCallback(() => {
    setIsOpenActiveScene((prev) => !prev);
  }, [setIsOpenActiveScene]);
  useEffect(() => {
    setIsOpenActiveScene(true);
  }, [activeSceneId]);
  return (
    <>
      <section className="create-new">
        <Button
          size="lg"
          color="transparent"
          className="btn-create-new-item"
          onClick={() => {
            addScene();
          }}
        >
          {t('Add New Scene')}
        </Button>
      </section>
      <section className="scene-list">
        <ul className="scenes">
          {sceneList?.map((scene) => (
            <li key={scene.id} onClick={() => onClickScene(scene.id)}>
              <section
                className={classNames(
                  'list-item scene',
                  activeSceneId === scene.id && 'active'
                )}
              >
                <SceneName scene={scene} />
                <IconButton
                  color="transparent"
                  className="btn-line-toggle"
                  onClick={() => {
                    toggleActiveScene();
                  }}
                >
                  {isOpenActiveScene && activeSceneId === scene.id ? (
                    <BottomArrow />
                  ) : (
                    <RightArrow />
                  )}
                </IconButton>
              </section>
              {isOpenActiveScene && activeSceneId === scene.id && (
                <section className="list-item-detail scene">
                  {lineList.map((line, index) => {
                    return (
                      <LineItem line={line} key={line.id} num={index + 1} />
                    );
                  })}
                </section>
              )}
            </li>
          ))}
        </ul>
      </section>
    </>
  );
};

const ScenesTab = () => {
  return (
    <StyledSceneTab className="scenes-tab">
      <SceneList />
    </StyledSceneTab>
  );
};
export default ScenesTab;
