import AlertModal from '@/components/Modal/AlertModal/AlertModal';
import { useWebSocketContext } from '@/providers/WebSocketProvider';
import { fetchAudio, getAudioBuffer } from '@/util/audio';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';

import {
  _addLine,
  _loadLines,
  _loadMusics,
  _loadProjects,
  _loadScenes,
  _loadTakes,
} from './api/project';
import FullScreenLoader from './components/FullScreenLoader';
import GlobalAudioPlayer from './GlobalAudioPlayer/GlobalAudioPlayer';
import useAxios from './hooks/useAxios';
import useVoiceProfile from './hooks/useVoiceProfile';
import MediaPanel from './MediaPanel/MediaPanel';
import { useDataContext } from './providers/DataContextProvider';
import SceneEditorContextProvider from './providers/SceneEditorContextProvider';
import TimelineContextProvider from './providers/TimelineContextProvider';
import QuickMenuPanel from './QuickMenuPanel/QuickMenuPanel';
import SceneWriterPanel from './SceneWriterPanel/SceneWriterPanel';
import { audioFileMapAtom } from './stores/atoms/audio';
import { musicListAtom } from './stores/atoms/music';
import {
  lineListAtom,
  sceneListAtom,
  selectedProjectIdAtom,
  takeListAtom,
} from './stores/atoms/project';
import {
  HEADER_HEIGHT,
  isLoadingProjectAtom,
  isModalOpenAtom,
  isShowFeedbackAtom,
  isShowMediaAtom,
  isShowTimelineAtom,
  isShowVoiceLibraryAtom,
  selectedQuickMenuAtom,
  timelinePanelHeightAtom,
} from './stores/atoms/ui';
import { projectVoiceProfileListAtom } from './stores/atoms/voice';
import { AudioFileMap } from './stores/audios';
import { DEFAULT_PARAMETER } from './stores/data/config';
import { extractFileName } from './stores/data/musics';
import {
  Draft,
  ExtendParameter,
  Music,
  Project,
  Scene,
  Take,
} from './stores/project';
import { useMusicFile } from './stores/recoilHooks/useMusicFile';
import { useResetUI } from './stores/recoilHooks/useResetUI';
import StyledScreenPlay from './styles/StyledScreenPlay';
import TimelinePanel from './TimelinePanel/TimelinePanel';
import VoiceLibrary from './VoiceLibrary/VoiceLibrary';

const Editor = () => {
  const { projectId: id } = useParams();
  const { sessionId } = useWebSocketContext();
  const [isLoadingProject, setIsLoadingProject] =
    useRecoilState(isLoadingProjectAtom);
  const [isModalOpen, setIsModalOpen] = useRecoilState(isModalOpenAtom);
  const isShowMedia = useRecoilValue(isShowMediaAtom);
  const isShowTimeline = useRecoilValue(isShowTimelineAtom);
  const [isShowFeedback, setIsShowFeedback] =
    useRecoilState(isShowFeedbackAtom);
  const timelinePanelHeight = useRecoilValue(timelinePanelHeightAtom);
  const selectedQuickMenu = useRecoilValue(selectedQuickMenuAtom);
  const isShowVoiceLibrary = useRecoilValue(isShowVoiceLibraryAtom);

  const { resetUI } = useResetUI();

  const [audioFileMap, setAudioFileMap] = useRecoilState(audioFileMapAtom);

  const {
    resetActiveInfo,
    takeJobIdMap,
    updateTakeJobIdMap,
    updateActiveSceneId,
    updateProjectInfo,
  } = useDataContext();
  const { loadVoiceProfileList } = useVoiceProfile();
  const { getMusicFileList } = useMusicFile();
  const navigate = useNavigate();

  const { getResourceInfo, createTts } = useAxios();
  const setSceneList = useSetRecoilState(sceneListAtom);
  const setLineList = useSetRecoilState(lineListAtom);
  const setTakeList = useSetRecoilState(takeListAtom);

  const initProject = useCallback(async () => {
    setIsLoadingProject(true);
    // id가 바뀔때마다 active된 Scene, Line, Take를 초기화 한다.
    resetActiveInfo();
    // project를 다시 부른다.
    const projectList = await _loadProjects();
    const activeProject = (projectList as Project[])?.find(
      (project) => project.id === id
    );
    // project 정보가 있으면 refreshProject를 실행하여 프로젝트를 초기화 하고, 아닌경우 projects로 이동한다.
    if (id && !!activeProject) {
      updateProjectInfo(activeProject);
      // TODO: 보이스 로드 후 프로젝트를 보여줘야할 것 같다.
      // 프로젝트가 로드 되는 순간 voice profile list 재호출
      await loadVoiceProfileList(id);
      // music 로드 전에 scene 정보 호출
      let scenes = (await _loadScenes(activeProject.id)) as Scene[];
      setSceneList(scenes);
      const activeScene = scenes[0];

      updateActiveSceneId(activeScene.id);
      // 라인이 없으면 추가
      if (activeScene.lineIds.length === 0) {
        const newLine = {
          id: id,
          sceneId: activeScene.id,
          projectId: id,
          draft: {
            text: '',
            parameter: DEFAULT_PARAMETER,
          } as Draft,
        };
        await _addLine(newLine);
        setLineList([newLine]);
      } else {
        // 라인이 있으면 라인 & 테이크 정보 호출
        const lineList = await _loadLines(activeScene.id);
        setLineList(lineList || []);
        const takeList = await _loadTakes(activeProject.id);
        setTakeList(takeList || []);
      }

      // 기본 제공 + 사용자가 업로드한 Music Files 호출
      await getMusicFileList(activeProject.id);

      // Music 호출
      let musics: Music[] = [];
      await Promise.all(
        scenes.map(async (scene) => {
          return (await _loadMusics(scene.id)) as Music[];
        })
      ).then((value: Music[][]) => {
        // resource_id를 기준으로 중복되지 않도록 filter
        musics = value.reduce((acc, cur) => {
          return acc.concat(
            cur.filter((music) => {
              return !musics.some((m) => m.resource_id === music.resource_id);
            })
          );
        }, [] as Music[]);
      });
      // take list 호출
      const takes = (await _loadTakes(activeProject.id)) || [];
      const audioFileList = [...musics, ...takes];

      if (audioFileList.length) {
        let newMap: AudioFileMap = {};
        let count = 0;
        await Promise.all(
          audioFileList.map(async (file) => {
            if (
              file.type !== 'music' &&
              file.id &&
              !file.resource_id &&
              !takeJobIdMap[file.id] &&
              !audioFileMap[file.id]
            ) {
              // todo generating 도중에 이탈한 것으로 간주하여 해당 take를 재생성한다.
              // cvc여부에 따라 호출 필요, 이후 중복 로직 hook으로 분리
              const res = await createTts(
                {
                  text: (file as Take).text,
                  language: (file as Take).language,
                  voice_id: (file as Take).voiceId,
                  parameters: {
                    ...((file as Take).parameter as ExtendParameter),
                  },
                  take_count: 1,
                },
                sessionId
              );
              const {
                data: {
                  data: { job_id },
                },
              } = res as any;
              updateTakeJobIdMap({ [file.id]: job_id });
            }
            if (
              audioFileMap[file.id] ||
              (file.resource_id && audioFileMap[file.resource_id])
            )
              return;
            try {
              let url = (file as Music).url;
              if (!url) {
                if (!file.resource_id) return;
                const { data } = await getResourceInfo(file.resource_id);
                url = data.data.transcoded[0].url;
              }
              if (url) {
                const audio = await fetchAudio(url);
                const audioBuffer = (await getAudioBuffer(
                  audio.arrayBuffer
                )) as AudioBuffer;
                // tmp: 임시로 music에 대해서 resource id로 map, file name을 저장
                if (file.type === 'music') {
                  const resourceId = (
                    file.url ? extractFileName(file.url) : file.resource_id
                  ) as string;
                  newMap[resourceId] = {
                    audioBuffer,
                    fileName: (file as Music).name,
                  };
                } else {
                  newMap[file.id] = { audioBuffer };
                }

                if (count === audioFileList.length - 1) {
                  setIsLoadingProject(false);
                } else {
                  count++;
                }
              }
            } catch (error) {
              console.error('Failed to load resource');
              setIsLoadingProject(false);
            }
          })
        );
        setAudioFileMap((prev) => {
          return {
            ...prev,
            ...newMap,
          };
        });
      }
      // 프로젝트 정보를 셋팅, 전체 프로젝트 아이템을 참조하는 곳에서 refresh 호출
      setIsLoadingProject(false);
      return true;
    } else {
      navigate('/projects');
      return false;
    }
  }, [
    id,
    navigate,
    updateProjectInfo,
    resetActiveInfo,
    setAudioFileMap,
    setIsLoadingProject,
    getMusicFileList,
    audioFileMap,
    loadVoiceProfileList,
    takeJobIdMap,
    updateTakeJobIdMap,
    sessionId,
    updateActiveSceneId,
    getResourceInfo,
    createTts,
    setSceneList,
    setLineList,
    setTakeList,
  ]);

  const setSelectedProjectId = useSetRecoilState(selectedProjectIdAtom);
  // id가 있을떄만 initProject를 실행
  useEffect(() => {
    if (id) {
      setSelectedProjectId(id);
      initProject();
      setIsShowFeedback(true);
    }
    return () => {
      setSelectedProjectId(null);
      resetActiveInfo();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const resetProjectVoiceProfileList = useResetRecoilState(
    projectVoiceProfileListAtom
  );
  const resetMusicList = useResetRecoilState(musicListAtom);

  useEffect(() => {
    // Editor 페이지를 벗어날때, ui 초기화
    return () => {
      // 프로젝트 변경시 UI 초기화
      resetUI();
      // 프로젝트 변경 시 데이터 초기화
      setSelectedProjectId(null);
      setLineList([]);
      setTakeList([]);
      resetProjectVoiceProfileList();
      resetMusicList();
      resetActiveInfo();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headerHeight = useMemo(
    () => (isShowFeedback ? HEADER_HEIGHT * 2 : HEADER_HEIGHT),
    [isShowFeedback]
  );

  return (
    <StyledScreenPlay>
      <section className="sp-middle-area">
        <section className="sp-middle-top">
          <SceneEditorContextProvider>
            <section
              className={classNames('scene-editor', isShowTimeline && 'narrow')}
            >
              <section
                className="scene-editor-top"
                style={{
                  height: isShowTimeline
                    ? `calc(100vh - ${headerHeight}rem - ${timelinePanelHeight}px)`
                    : `calc(100vh - ${headerHeight}rem`,
                }}
              >
                {selectedQuickMenu && <QuickMenuPanel />}
                <SceneWriterPanel />

                {isShowMedia && <MediaPanel />}
              </section>
            </section>
            {isShowVoiceLibrary && <VoiceLibrary />}
          </SceneEditorContextProvider>
        </section>
        <div className="sp-bottom-area">
          <TimelineContextProvider>
            <GlobalAudioPlayer />
            {isShowTimeline && <TimelinePanel />}
          </TimelineContextProvider>
        </div>
      </section>
      {isLoadingProject && <FullScreenLoader />}
      {isModalOpen && (
        <AlertModal
          isOpen={isModalOpen}
          onConfirm={() => setIsModalOpen(false)}
          message="현재 버전에서는 사용할 수 없는 기능입니다."
          confirmText="OK"
        />
      )}
    </StyledScreenPlay>
  );
};
export default Editor;
