import { atom } from 'recoil';

import { AudioFileMap } from '../audios';
import { Profile, ProfileEditInfo } from '../voice';

export const voiceProfileListAtom = atom<Profile[]>({
  key: 'voiceProfileList',
  default: [],
});

export const projectVoiceProfileListAtom = atom<Profile[]>({
  key: 'projectVoiceProfileList',
  default: [],
});

export const voiceFileMapAtom = atom<Omit<AudioFileMap, 'fileName'>>({
  key: 'voiceFileMap',
  default: {},
});

export const editingVoiceProfileIdAtom = atom<string | null>({
  key: 'editingVoiceProfileId',
  default: null,
});

export const profileEditInfoListAtom = atom<ProfileEditInfo[]>({
  key: 'profileEditInfoList',
  default: [],
});
