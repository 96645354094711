import { ReactComponent as WorkLogo } from '@/assets/images/Work.svg';
import { ReactComponent as SupertonePlay } from '@/pages/screenplay/assets/images/SupertonePlay.svg';

import { StyledBrandWrapper } from './styled/StyledLanding';

const BrandWrapper = () => (
  <StyledBrandWrapper>
    <section className="logo-product-screenplay">
      <SupertonePlay className="logo-screenplay" />
    </section>
    <WorkLogo className="logo-work" />
  </StyledBrandWrapper>
);

export default BrandWrapper;
