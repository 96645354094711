import IconButton from '@/components/Button/IconButton';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as BottomArrow } from '../assets/icons/BottomArrow.svg';
import { ReactComponent as RightArrow } from '../assets/icons/RightArrow.svg';
import { useDataContext } from '../providers/DataContextProvider';
import { useSceneEditorContext } from '../providers/SceneEditorContextProvider';
import { Line } from '../stores/project';

const LineItem: React.FC<{ line: Line; num: number }> = ({ line, num }) => {
  const { t } = useTranslation();
  const { updateActiveLineId, updateActiveTakeId } = useDataContext();
  const { takeList } = useSceneEditorContext();
  const takes = useMemo(
    () => takeList.filter((take) => take.lineId === line.id),
    [line.id, takeList]
  );
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleItem = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);
  const isMultiTakes = useMemo(() => takes.length > 1, [takes]);
  const onSelectLine = useCallback(async () => {
    updateActiveLineId(line.id);
    if (line.selectedTakeId) {
      await updateActiveTakeId(line.selectedTakeId);
    }
  }, [updateActiveLineId, updateActiveTakeId, line.id, line.selectedTakeId]);

  return (
    <ul key={`line-${line.id}`}>
      <li className={classNames('line-item', isMultiTakes && 'multi-takes')}>
        <section
          className="line-item-title"
          onClick={(e) => {
            e.stopPropagation();
            onSelectLine();
          }}
        >
          <strong className="line-icon">
            {t('Line')} {num}
          </strong>
          {/* {fixme line.selectedTakeId text를 필요로 하는 부분, 프로젝트와 여기에서 쓰이는 것으로 보아 state로 관리가 필요} */}
          <span className="line-text">
            {takeList.find(({ id }) => id === line.selectedTakeId)?.text}
          </span>
          <IconButton
            color="transparent"
            className="btn-line-toggle"
            onClick={toggleItem}
          >
            {isOpen ? <BottomArrow /> : <RightArrow />}
          </IconButton>
        </section>
        {isOpen && (
          <ul className="take-list">
            {takes.map((take, index) => {
              const isSelected =
                takes.length > 1 && line.selectedTakeId === take.id;
              return (
                <li
                  key={`take-${take.id}`}
                  className={classNames('take-item', isSelected && 'selected')}
                >
                  <span className="take-icon">
                    {t('Take')} {index + 1}
                  </span>
                  {isSelected && (
                    <span className="selected-icon">{t('Selected')}</span>
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </li>
    </ul>
  );
};

export default LineItem;
