import { v4 as uuid } from 'uuid';

import {
  ERROR_MULTIPLE_TAB_ACCESS,
  defaultLanguage,
} from '../stores/data/config';
import {
  Line,
  Music,
  MusicFile,
  Project,
  Scene,
  Take,
} from '../stores/project';
import { ProfileEditInfo } from '../stores/voice';

const DB_NAME = 'PSP_DB';
const DB_VERSION = 1;
const PROJECT_LIST = 'projects';
const SCENE_LIST = 'scenes';
const LINE_LIST = 'lines';
const VOICE_LIST = 'voices';
const TAKE_LIST = 'takes';
const MUSIC_LIST = 'musics';
const MUSIC_FILE_LIST = 'musicFiles';

const STORE_LIST = [
  PROJECT_LIST,
  SCENE_LIST,
  LINE_LIST,
  VOICE_LIST,
  TAKE_LIST,
  MUSIC_LIST,
  MUSIC_FILE_LIST,
];

const openDB = async (): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    const openRequest = indexedDB.open(DB_NAME, DB_VERSION);
    openRequest.onupgradeneeded = function (e: any) {
      const db = e.target.result as IDBDatabase;
      for (const store of STORE_LIST) {
        if (!db.objectStoreNames.contains(store)) {
          db.createObjectStore(store);
        }
      }
    };

    openRequest.onerror = function (e) {
      reject('Error opening db');
    };

    openRequest.onsuccess = function (event: Event) {
      const db = (event.target as IDBOpenDBRequest)?.result;
      resolve(db as IDBDatabase);
    };
  });
};

const addData = async <T extends { id: string; projectId?: string }>(
  storeName: string,
  data: T
): Promise<void> => {
  const db = await openDB();
  return new Promise((resolve, reject) => {
    let tx = db.transaction(storeName, 'readwrite');
    let store = tx.objectStore(storeName);

    store.add({ ...data, createdAt: new Date() }, data.id);

    tx.oncomplete = function () {
      if (data?.projectId) {
        _updateProject(data.projectId);
      }
      resolve();
    };

    tx.onerror = function () {
      reject('Error adding item to db');
    };
  });
};

const getData = async <T>(
  storeName: string,
  key?: string
): Promise<T | undefined> => {
  const db = await openDB();
  return new Promise((resolve, reject) => {
    let transaction = db.transaction(storeName, 'readonly');
    let store = transaction.objectStore(storeName);

    if (key === undefined) {
      const getRequest = store.getAll();
      getRequest.onerror = function () {
        reject('Error getting item from db');
      };

      getRequest.onsuccess = function (e: any) {
        resolve(e.target.result as T);
      };
    } else {
      const getRequest = store.get(key);
      getRequest.onerror = function () {
        reject('Error getting item from db');
      };

      getRequest.onsuccess = function (e: any) {
        resolve(e.target.result as T);
      };
    }
  });
};

const deleteData = async (storeName: string, key: string): Promise<void> => {
  const db = await openDB();
  return new Promise(async (resolve, reject) => {
    const deleteData = await getData<{ projectId?: string }>(storeName, key);
    let tx = db.transaction(storeName, 'readwrite');
    let store = tx.objectStore(storeName);
    try {
      store.delete(key);
    } catch {
      reject(ERROR_MULTIPLE_TAB_ACCESS);
    }

    tx.oncomplete = function () {
      if (deleteData?.projectId) {
        _updateProject(deleteData.projectId);
      }
      resolve();
    };

    tx.onerror = function () {
      reject('Error deleting item from db');
    };
  });
};

const updateData = async <T extends { id: string }>(
  storeName: string,
  data: T
): Promise<void> => {
  const db = await openDB();
  return new Promise((resolve, reject) => {
    let tx = db.transaction(storeName, 'readwrite');
    let store = tx.objectStore(storeName);
    const updatedAt = new Date();
    try {
      store.put({ ...data, updatedAt }, data.id);
    } catch {
      reject(ERROR_MULTIPLE_TAB_ACCESS);
    }

    tx.oncomplete = async () => {
      const putData = await getData<{ projectId?: string }>(storeName, data.id);
      if (putData?.projectId) {
        _updateProject(putData?.projectId);
      }
      resolve();
    };

    tx.onerror = () => {
      reject('Error updating item in db');
    };
  });
};

const curryingWithException = <ResultType>(fn: Function) => {
  return async (...args: any[]) => {
    try {
      return (await fn(...args)) as ResultType;
    } catch (err) {
      if (err === ERROR_MULTIPLE_TAB_ACCESS) {
        window.location.assign('/error-multiple-tab');
      } else {
        console.error(err);
      }
    }
  };
};

// 프로젝트 목록 조회
export const _loadProjects = curryingWithException<Project[]>(async () => {
  // todo project 생성 및 update 타이밍에 따른 처리 필요
  const result = (await getData(PROJECT_LIST)) as Project[];
  result.sort((a, b) => {
    if (a.createdAt && b.createdAt) {
      return a.createdAt > b.createdAt ? -1 : 1;
    }
    return 0;
  });
  return result;
});

// 데이터베이스 연결
export const initIndexedDB = async () => {
  await openDB();
  await _loadProjects();
};

// 프로젝트 추가
export const _addProject = curryingWithException<Project>(
  async (project?: Project) => {
    const id = uuid();
    await addData(
      PROJECT_LIST,
      project || {
        id: id,
        name: 'New Project',
        language: defaultLanguage,
      }
    );
    return await getData(PROJECT_LIST, id);
  }
);

// 프로젝트 조회
export const _loadProject = curryingWithException<Project>(
  async (projectId: string) => {
    // todo project 생성 및 update 타이밍에 따른 처리 필요
    const result = await getData(PROJECT_LIST, projectId);
    return result as Project;
  }
);

// 프로젝트 삭제
export const _deleteProject = curryingWithException<Project[]>(
  async (projectId: string) => {
    await deleteData(PROJECT_LIST, projectId);
    const result = await getData(PROJECT_LIST);
    return result;
  }
);

// 프로젝트 수정
export const _updateProject = curryingWithException<Project>(
  async (project: Project | string) => {
    if (typeof project === 'string') {
      const result = (await getData(PROJECT_LIST, project)) as Project;
      if (result) {
        await updateData(PROJECT_LIST, { ...result, updatedAt: new Date() });
      }
      return await getData(PROJECT_LIST, project);
    } else {
      await updateData(PROJECT_LIST, project);
      return await getData(PROJECT_LIST, project.id);
    }
  }
);

// 씬 목록 조회
export const _loadScenes = curryingWithException<Scene[]>(
  async (projectId?: string) => {
    const scene = await getData<Scene[]>(SCENE_LIST);
    const projectScene = projectId
      ? scene?.filter((scene) => scene.projectId === projectId)
      : scene;
    projectScene?.sort((a, b) => {
      if (a.createdAt && b.createdAt) {
        return a.createdAt > b.createdAt ? -1 : 1;
      }
      return 0;
    });
    return projectScene as Scene[];
  }
);

export const _loadScene = curryingWithException<Scene>(
  async (sceneId: string) => {
    const result = await getData<Scene>(SCENE_LIST, sceneId);
    return result;
  }
);

// 씬 추가
export const _addScene = curryingWithException<Scene>(
  async (projectId: string, scene?: Scene) => {
    const data = scene || {
      id: uuid(),
      projectId: projectId,
      name: `New Scene`,
      lineIds: [] as string[],
    };
    await addData(SCENE_LIST, data);
    return await getData<Scene>(SCENE_LIST, data.id);
  }
);

// 씬 삭제
export const _deleteScene = curryingWithException<Scene[]>(
  async (sceneId: string) => {
    const scene = (await getData<Scene>(SCENE_LIST, sceneId)) as Scene;
    await Promise.all(
      scene?.lineIds?.map(async (lineId) => await _deleteLine(lineId, false))
    );
    await deleteData(SCENE_LIST, sceneId);
    const result = await getData(SCENE_LIST);
    return result as Scene[];
  }
);

// 씬 수정
export const _updateScene = curryingWithException<Scene>(
  async (scene: Scene) => {
    await updateData(SCENE_LIST, scene);
    const result = await getData(SCENE_LIST, scene.id);
    return result as Scene;
  }
);

// 라인 목록 조회, 라인 순서는 Scene이 가지고 있는다.
export const _loadLines = curryingWithException<Line[]>(
  async (sceneId?: string) => {
    const scene = await getData<Scene>(SCENE_LIST, sceneId);
    const result = await getData<Line[]>(LINE_LIST);
    return sceneId
      ? (scene?.lineIds
          ?.map((id) => result?.find((line) => line.id === id))
          .filter((line) => line !== undefined) as Line[])
      : (result as Line[]);
  }
);

// 라인 추가
export const _addLine = curryingWithException<Line>(
  async (line: Line, standId?: string, updateScene: boolean = true) => {
    if (standId !== undefined) {
      const scene = await getData<Scene>(SCENE_LIST, line.sceneId);
      const lineIds = scene?.lineIds || [];
      const index = scene?.lineIds?.indexOf(standId) || 0;
      lineIds.splice(index + 1, 0, line.id);
      await _updateScene({ ...scene, lineIds } as Scene);
      await addData(LINE_LIST, line);
      return await getData(LINE_LIST, line.id);
    } else {
      if (line.id === undefined) {
        line.id = uuid();
      }
      if (updateScene) {
        const scene = await getData<Scene>(SCENE_LIST, line.sceneId);
        const lineIds = (scene?.lineIds || []).concat(line.id);
        await _updateScene({ ...scene, lineIds } as Scene);
        await addData(LINE_LIST, line);
        return await getData(LINE_LIST, line.id);
      } else {
        await addData(LINE_LIST, line);
        return await getData(LINE_LIST, line.id);
      }
    }
  }
);

// 라인 삭제
export const _deleteLine = curryingWithException<Scene>(
  async (lineId: string, updateScene: boolean = true) => {
    if (updateScene) {
      const line = await getData<Line>(LINE_LIST, lineId);
      const scene = await getData<Scene>(SCENE_LIST, line?.sceneId);
      const lineIds = scene?.lineIds?.filter((id) => id !== lineId);
      await _updateScene({ ...scene, lineIds } as Scene);
      await deleteData(LINE_LIST, lineId);
      return await getData(LINE_LIST);
    } else {
      await deleteData(LINE_LIST, lineId);
      return await getData(LINE_LIST);
    }
  }
);

// 라인 멀티플 삭제
export const _deleteLines = curryingWithException(
  async (lineIds: string[], sceneId: string) => {
    await Promise.all(
      lineIds.map(async (lineId) => {
        await deleteData(LINE_LIST, lineId);
        await _deleteTakes(lineId);
      })
    );
    return await _loadLines(sceneId);
  }
);

// 라인 수정
export const _updateLine = curryingWithException<Line>(async (line: Line) => {
  await updateData(LINE_LIST, line);
  return await getData(LINE_LIST, line.id);
});

export const _updateLines = curryingWithException<Line[]>(
  async (lines: Line[]) => {
    Promise.all(
      lines.map(async (line) => {
        await updateData(LINE_LIST, line);
      })
    );
    return await _loadLines(lines[0].sceneId);
  }
);

// 테이크 목록 조회
export const _loadTakes = curryingWithException<Take[]>(
  async (projectId?: string) => {
    const result = await getData<Take[]>(TAKE_LIST);
    if (!projectId) return result;
    const takes = result?.filter((take) => take.projectId === projectId);
    takes?.sort((a, b) => {
      if (a.createdAt && b.createdAt) {
        return a.createdAt.getTime() < b.createdAt.getTime() ? -1 : 1;
      }
      return 0;
    });
    return takes;
  }
);

// 테이크 추가
export const _addTake = curryingWithException<Take[]>(
  async (take: Take | Take[]) => {
    if (Array.isArray(take)) {
      for (const t of take) {
        await addData(TAKE_LIST, t);
      }
    } else {
      await addData(TAKE_LIST, take);
    }
    return await getData<Take[]>(TAKE_LIST);
  }
);

// 테이크 삭제
export const _deleteTake = curryingWithException<Take[]>(
  async (takeId: string) => {
    await deleteData(TAKE_LIST, takeId);
    return await getData(TAKE_LIST);
  }
);

// 라인에 종속된 테이크들 삭제
export const _deleteTakes = curryingWithException<Take[]>(
  async (lineId: string) => {
    const takes = await getData<Take[]>(TAKE_LIST);
    const lineTakes = takes?.filter((take) => take.lineId === lineId);
    if (lineTakes) {
      for (const take of lineTakes) {
        await deleteData(TAKE_LIST, take.id);
      }
    }
    return await getData(TAKE_LIST);
  }
);

// 테이크 수정
export const _updateTake = curryingWithException<Take[]>(async (take: Take) => {
  await updateData(TAKE_LIST, take);
  return await getData(TAKE_LIST);
});

export const _updateTakes = curryingWithException<Take[]>(
  async (takes: Take[]) => {
    Promise.all(
      takes.map(async (take) => {
        await updateData(TAKE_LIST, take);
      })
    );
    return await getData(TAKE_LIST);
  }
);

// Voice 정보 조회
export const _loadProfileEditInfoList = curryingWithException<
  ProfileEditInfo[]
>(async (projectId: string) => {
  const result = await getData<ProfileEditInfo[]>(VOICE_LIST);
  return result?.filter((voice) => voice.projectId === projectId);
});

// Voice 정보 추가
export const _addProfileEditInfo = curryingWithException<ProfileEditInfo[]>(
  async (editInfo: ProfileEditInfo) => {
    await addData(VOICE_LIST, editInfo);
    return await _loadProfileEditInfoList(editInfo.projectId);
  }
);

// Voice 정보 삭제
export const _deleteProfileEditInfo = curryingWithException<ProfileEditInfo[]>(
  async (editInfo: ProfileEditInfo) => {
    await deleteData(VOICE_LIST, editInfo.id);
    return await _loadProfileEditInfoList(editInfo.projectId);
  }
);

// Voice 정보 수정
export const _updateProfileEditInfo = curryingWithException<ProfileEditInfo[]>(
  async (editInfo: ProfileEditInfo) => {
    await updateData(VOICE_LIST, editInfo);
    return await _loadProfileEditInfoList(editInfo.projectId);
  }
);

// 음악 목록 조회
export const _loadMusics = curryingWithException<Music[]>(
  async (sceneId: string) => {
    const result = await getData<Music[]>(MUSIC_LIST);
    return result?.filter((music) => music.sceneId === sceneId);
  }
);

// 음악 추가
export const _addMusic = curryingWithException<Music[]>(
  async (music: Music) => {
    await addData(MUSIC_LIST, music);
    return await _loadMusics(music.sceneId);
  }
);

// 음악 삭제
export const _deleteMusic = curryingWithException<Music[]>(
  async (music: Music) => {
    await deleteData(MUSIC_LIST, music.id);
    return await _loadMusics(music.sceneId);
  }
);

// 음악 수정
export const _updateMusic = curryingWithException<Music[]>(
  async (music: Music) => {
    await updateData(MUSIC_LIST, music);
    return await _loadMusics(music.sceneId);
  }
);

// 음악 파일 목록 조회
export const _loadMusicFiles = curryingWithException<Music[]>(
  async (projectId: string) => {
    const result = await getData<MusicFile[]>(MUSIC_FILE_LIST);
    // if createdAt is exist, sort by createdAt
    return result
      ?.filter((music) => music.projectId === projectId)
      .sort((a, b) => {
        if (a.createdAt && b.createdAt) {
          return a.createdAt > b.createdAt ? 1 : -1;
        }
        return 0;
      });
  }
);

// 음악 파일 추가
export const _addMusicFiles = curryingWithException<MusicFile[]>(
  async (musicFiles: MusicFile[]) => {
    for (const musicFile of musicFiles) {
      await addData(MUSIC_FILE_LIST, musicFile);
    }
    return await _loadMusicFiles(musicFiles[0].projectId as string);
  }
);

// 음악 파일 삭제
export const _deleteMusicFile = curryingWithException<MusicFile[]>(
  async (musicFile: MusicFile) => {
    await deleteData(MUSIC_FILE_LIST, musicFile.id);
    return await _loadMusicFiles(musicFile.projectId as string);
  }
);

// 음악 파일 수정
export const _updateMusicFile = curryingWithException<MusicFile[]>(
  async (musicFile: MusicFile) => {
    await updateData(MUSIC_FILE_LIST, musicFile);
    return await _loadMusicFiles(musicFile.projectId as string);
  }
);
