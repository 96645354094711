import IconButton from '@/components/Button/IconButton';
import { useSetRecoilState } from 'recoil';

import { ReactComponent as DeleteIcon } from '../assets/icons/DeleteIcon.svg';
import { isShowFeedbackAtom } from '../stores/atoms/ui';
import { DISCORD_URL, ZENDESK_URL } from '../stores/data/config';

const Feedback = () => {
  const setIsShowFeedback = useSetRecoilState(isShowFeedbackAtom);
  return (
    <section className="project-feedback">
      <section className="feedback-text">
        Any feedback? Reach out to us through{' '}
        <a href={DISCORD_URL} target="_blank" rel="noreferrer">
          Discord
        </a>{' '}
        /{' '}
        <a target="_blank" href={ZENDESK_URL} rel="noreferrer">
          Zendesk
        </a>
      </section>
      <IconButton className="btn-exit" onClick={() => setIsShowFeedback(false)}>
        <DeleteIcon />
      </IconButton>
    </section>
  );
};
export default Feedback;
