import './App.css';
import './assets/locale/i18n';

import Hotjar from '@hotjar/browser';
import { useSUPAuth } from '@supertone-inc/auth-sdk-react';
import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import Login from './layout/Auth/Login';
import PrivateOutlet from './layout/Auth/PrivateOutlet';
import Error from './layout/Error/Error';
import MultipleTabError from './layout/Error/MultipleTabError';
import ZeroShotPage from './pages/admin/zeroshot/ZeroShotPage';
import IndexPage from './pages/index/IndexPage';
import Layout from './pages/Layout';
import MessagePage from './pages/message/MessagePage';
import NeedsApproval from './pages/message/NeedsApproval';
import Editor from './pages/screenplay/Editor';
import DataContextProvider from './pages/screenplay/providers/DataContextProvider';
import Projects from './pages/screenplay/QuickMenuPanel/Projects';
import { WebSocketContextProvider } from './providers/WebSocketProvider';
import { BasicFontSize } from './styles/Typography';
import { getGroupInfo } from './util/user';

const PERMISSION_SUPERTONE = '/company/supertone';

/**
 * fixme darcy 빌드 에러를 방지하기 위한 수정이 되어 있습니다. 무시하고 덮어 쓰셔요~
 */
function App() {
  const { user } = useSUPAuth();

  // Hotjar 초기화
  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'dev') return;
    const siteId = Number(process.env.REACT_APP_HOTJAR_SITE_ID);
    const version = Number(process.env.REACT_APP_HOTJAR_VERSION);

    // email이 없는 사용자는 정상적인 사용자가 아니므로 hotjar 초기화하지 않음
    if (user?.email && !isNaN(siteId) && !isNaN(version)) {
      Hotjar.init(siteId, version);
      // 개인정보로 간주되는 정보들은 cbt버전에서만 수집, 이후 파기가 필요함
      const group_info = getGroupInfo(user?.permissions);
      Hotjar.identify(user.email, {
        email: user.email,
        group_info: group_info.join(),
      });
    }
  }, [user?.permissions, user?.email]);

  useEffect(() => {
    // 개발 환경, 로그인이 되어있지 않은 경우, hotjar가 이미 init되어 있는 경우에는 초기화하지 않음
    if (!user?.email || !Hotjar.isReady()) return;
    // supertone 계정이 아닌 경우에만 hotjar 실행
    const isSupertone = user.permissions?.some((permission) =>
      permission.toLowerCase().includes(PERMISSION_SUPERTONE)
    );
    if (isSupertone) return;
    Hotjar.event('start_hotjar');
  }, [user]);

  // Set basic font size
  useEffect(() => {
    document.documentElement.style.fontSize = `${BasicFontSize}px`;
  }, []);

  return (
    <WebSocketContextProvider>
      <RecoilRoot>
        <DataContextProvider>
          <Routes>
            <Route path="/signin" element={<Login />} />
            <Route
              path="/error"
              element={<MessagePage message={<Error />} />}
            />
            <Route path="/error-multiple-tab" element={<MultipleTabError />} />
            <Route
              path="/message/needs-approval"
              element={<MessagePage message={<NeedsApproval />} />}
            />
            <Route path="/" element={<PrivateOutlet />}>
              <Route index element={<IndexPage />} />
              <Route path="projects" element={<Projects />} />
              <Route path=":projectId" element={<Layout />}>
                <Route path="editor" element={<Editor />} />
              </Route>
            </Route>
            <Route
              element={
                // supertone 구성원만 접근 가능
                <PrivateOutlet requiredPermission={PERMISSION_SUPERTONE} />
              }
            >
              <Route path="admin/zero-shot" element={<ZeroShotPage />}></Route>
            </Route>
          </Routes>
        </DataContextProvider>
      </RecoilRoot>
    </WebSocketContextProvider>
  );
}

export default App;
