import IconButton from '@/components/Button/IconButton';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { ReactComponent as FoldingIcon } from '../assets/icons/FoldingIcon.svg';
import { useLog } from '../hooks/useLog/useLog';
import { isShowTimelineAtom } from '../stores/atoms/ui';
import { useToggleTimeline } from '../stores/recoilHooks/useToggles';

const PanelButtonGroup = () => {
  const { toggleTimeline } = useToggleTimeline();
  const isShowTimeline = useRecoilValue(isShowTimelineAtom);
  const { track } = useLog();
  const handleToggleTimeline = useCallback(() => {
    if (isShowTimeline) {
      track('COLLAPSE_TIMELINE');
    } else {
      track('EXPAND_TIMELINE');
    }
    toggleTimeline();
  }, [isShowTimeline, track, toggleTimeline]);

  return (
    <section className="panel-buttons">
      <IconButton
        className={classNames('icon-timeline', isShowTimeline && 'active')}
        variant="none"
        onClick={handleToggleTimeline}
        isFillCurrentColor={false}
      >
        <FoldingIcon />
      </IconButton>
    </section>
  );
};

export default PanelButtonGroup;
