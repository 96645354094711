import IconButton from '@/components/Button/IconButton';
import { Grey } from '@/styles/Colors';
import { useCallback, useMemo } from 'react';

import { ReactComponent as BottomArrow } from '../assets/icons/BottomArrow.svg';
import { ReactComponent as RightArrow } from '../assets/icons/RightArrow.svg';
import { AgeMap, FILTER_OPTIONS, LanguageMap } from './const';
import { Filter, Language } from './types';

interface FilterOptionProps {
  filter: Filter;
  updateFilter: (filter: Filter) => void;
}

const VoiceFilter = ({ filter, updateFilter }: FilterOptionProps) => {
  const filterLength = useMemo(
    () => filter.options.filter((option) => option.value).length,
    [filter.options]
  );

  const getFilterLabel = useCallback((title: string, label: string) => {
    const key = FILTER_OPTIONS.find((item) => item.title === title)?.key;
    switch (key) {
      case 'language':
        return LanguageMap[label as Language];
      case 'age':
        return AgeMap[label];
      default:
        return label;
    }
  }, []);

  return (
    <li>
      <h4 className="filter-title">{filter.title}</h4>
      {filter.isCollapsed && filterLength > 0 && (
        <span className="filter-length">{filterLength}</span>
      )}
      <IconButton
        variant="none"
        color={Grey[200]}
        isFillCurrentColor={false}
        onClick={() =>
          updateFilter({ ...filter, isCollapsed: !filter.isCollapsed })
        }
      >
        {filter.isCollapsed ? <RightArrow /> : <BottomArrow />}
      </IconButton>
      {!filter.isCollapsed && (
        <ul className="filter-list">
          {filter.options.map((option) => (
            <li key={option.label}>
              <input
                id={option.label}
                type="checkbox"
                checked={option.value}
                readOnly
              />
              <label
                htmlFor={option.label}
                onClick={() =>
                  updateFilter({
                    ...filter,
                    options: filter.options.map((item) =>
                      item.label === option.label
                        ? { ...item, value: !item.value }
                        : item
                    ),
                  })
                }
                // tmp: 서버에서 key, label 도입 시 제거 예정
                style={
                  filter.title === 'Age'
                    ? {
                        textTransform: 'initial',
                      }
                    : {}
                }
              >
                {getFilterLabel(filter.title, option.label)}
              </label>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default VoiceFilter;
