import Landing from '@/assets/images/Landing.png';
import styled from '@emotion/styled';

export const StyledLanding = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url(${Landing});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const StyledBrandWrapper = styled.div`
  .logo-company {
    position: absolute;
    top: 3.13rem;
    left: 3.44rem;
  }
  .logo-product {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &-screenplay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .logo-screenplay {
    width: 70rem;
    height: auto;
  }
  .logo-work {
    position: absolute;
    right: 3.81rem;
    bottom: 3.13rem;
  }
  .logo-screenplay-cbt {
    position: absolute;
    right: 0;
    top: -4rem;
    width: 13rem;
    height: auto;
  }
`;
