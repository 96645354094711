import IconButton from '@/components/Button/IconButton';
import { Dropdown } from '@/components/Dropdown';
import { ReactComponent as MoreIcon } from '@/pages/screenplay/assets/icons/MoreIcon.svg';
import { ReactComponent as AddIcon } from '@/pages/screenplay/assets/icons/project/AddIcon.svg';
import { ReactComponent as ImportIcon } from '@/pages/screenplay/assets/icons/project/ImportIcon.svg';
import { ReactComponent as Logo } from '@/pages/screenplay/assets/images/PlayLogo.svg';
import { useSUPAuth } from '@supertone-inc/auth-sdk-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import Button from '../../../components/Button/Button';
import { Primary } from '../../../styles/Colors';
import {
  _addLine,
  _addMusic,
  _addMusicFiles,
  _addProfileEditInfo,
  _addProject,
  _addScene,
  _addTake,
  _deleteProject,
  _loadLines,
  _loadProjects,
  _loadScenes,
  _updateProject,
} from '../api/project';
import useAxios from '../hooks/useAxios';
import { useLog } from '../hooks/useLog/useLog';
import { projectListAtom } from '../stores/atoms/project';
import { defaultLanguage } from '../stores/data/config';
import { Line, Project, Scene, Take } from '../stores/project';
import { ProfileEditInfo } from '../stores/voice';
import ProjectItem from './ProjectItem';
import StyledProjects, { StyledProjectImportDropDown } from './StyledProjects';

const ProjectList = () => {
  const { user } = useSUPAuth();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [projectList, setProjectList] = useRecoilState(projectListAtom);
  const loadProjectList = useCallback(async () => {
    const projectList = (await _loadProjects()) as Project[];
    // scene, line 정보를 가져와서 projectList에 추가
    const scenes = await _loadScenes();
    const lines = await _loadLines();
    setProjectList(
      projectList?.map((project) => {
        const projectScenes =
          scenes?.filter((scene) => scene.projectId === project.id) || [];
        return {
          ...project,
          sceneCount: projectScenes.length,
          lineCount:
            lines?.filter((line) =>
              projectScenes.some((scene) => scene.id === line.sceneId)
            ).length || 0,
        };
      })
    );
  }, [setProjectList]);

  const { track } = useLog();

  const addProject = useCallback(async () => {
    const project = (await _addProject()) as Project;
    await _addScene(project.id);
    track('ADD_NEW_PROJECT', { projectId: project.id });
    loadProjectList();
    navigate(`/${project.id}/editor`);
  }, [loadProjectList, navigate, track]);
  const onChangeProjectName = useCallback(
    async (id: string, name: string) => {
      const project = (projectList as Project[]).find(
        (project) => project.id === id
      );
      if (!project) return;
      await _updateProject({ ...project, name });
      loadProjectList();
    },
    [projectList, loadProjectList]
  );

  const removeProject = useCallback(
    async (id: string) => {
      await _deleteProject(id);
      loadProjectList();
    },
    [loadProjectList]
  );

  const isAdmin = useMemo(() => {
    let isAdmin = false;
    if (!user?.permissions) return isAdmin;
    user.permissions.forEach((permission) => {
      // tmp: 추후 대소문자 정보 바뀔 수 있으므로 소문자로 변경 후 비교
      permission.toLowerCase().includes('/company/supertone') &&
        (isAdmin = true);
    });
    return isAdmin;
  }, [user?.permissions]);
  const { getVoiceLibrary } = useAxios();

  const setProject = useCallback(
    async (project: any) => {
      // 프로젝트가 이미 존재하거나 아이디가 없는 파일은 제거
      if (
        project.id === undefined ||
        projectList.some(({ id }) => id === project.id)
      )
        return;
      // 프로젝트를 정보를 indexedDB에 추가하기 전, 권한이 없어진 voice정보는 전부 제거 한다.
      const profile = await getVoiceLibrary();
      const voiceList = profile?.reduce((acc, profile) => {
        return acc.concat(profile.voices.map((voice) => voice.id));
      }, [] as string[]);
      // voice포함한 테이크 제거
      const takes = (project.takes as Take[])?.filter((take) =>
        voiceList?.includes(take.voiceId)
      );
      const lines = (project.lines as Line[])?.filter((line) =>
        voiceList?.includes(line.draft?.voiceId || '')
      );
      // 라인 아이디가 없는경우 제거
      const scenes = (project.scenes as Scene[])?.map((scene) => {
        const lineIds = scene.lineIds;
        return {
          ...scene,
          lineIds: lineIds.filter((lineId) =>
            lines?.some((line) => line.id === lineId)
          ),
        };
      });
      // profileEditInfo 도 profile에 있는 정보만 필터
      const profileEditInfoList = (
        project.profileEditInfoList as ProfileEditInfo[]
      )?.filter((profileEditInfo) =>
        profile?.some((profile) => profile.id === profileEditInfo.profileId)
      );
      await _addProject({
        id: project.id,
        name: project.name,
        language: project.language || defaultLanguage,
      } as Project);
      await Promise.all(takes.map(async (take: any) => await _addTake(take)));
      await Promise.all(
        scenes.map(async (scene: any) => await _addScene(project.id, scene))
      );
      await Promise.all(
        lines.map(async (scene: any) => await _addLine(scene, undefined, false))
      );
      await Promise.all(
        project.musics?.map(async (music: any) => await _addMusic(music))
      );
      project.musicFiles?.length && (await _addMusicFiles(project.musicFiles));
      await Promise.all(
        profileEditInfoList.map(
          async (profileEditInfo: any) =>
            await _addProfileEditInfo(profileEditInfo)
        )
      );
      loadProjectList();
    },
    [loadProjectList, projectList, getVoiceLibrary]
  );

  const [isProjectImportLayerOpen, setIsProjectImportLayerOpen] =
    useState(false);
  const toggleProjectImportLayer = useCallback(() => {
    setIsProjectImportLayerOpen((prev) => !prev);
  }, []);

  const importProject = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // setup database and add project finally
      let reader = new FileReader();
      reader.onloadend = function (evt: ProgressEvent<FileReader>) {
        if (evt.target?.readyState === FileReader.DONE) {
          let content = JSON.parse(evt.target.result as string);
          if (content?.id) {
            setProject(content);
          }
        }
      };
      if (e.target.files) {
        const file = e.target.files[0];
        file && reader.readAsText(file);
      }
      setIsProjectImportLayerOpen(false);
    },
    [setProject]
  );

  useEffect(() => {
    loadProjectList();
  }, [loadProjectList]);

  return (
    <StyledProjects className="projects">
      <section className="header">
        <section className="screenplay project-header">
          <h1 className="logo">
            <Logo />
          </h1>
        </section>
        {/* tmp: zeroshot */}
        {isAdmin && (
          <Button
            variant="outlined"
            className="btn-admin"
            color={Primary[400]}
            size="sm"
            onClick={() => {
              navigate('/admin/zero-shot');
            }}
          >
            Zero Shot
          </Button>
        )}
      </section>
      <section className="project-wrap">
        <section className="project-title">
          <h2>{t('Projects')}</h2>
          <section>
            <Dropdown
              anchorElement={
                <IconButton
                  className="btn-project-more"
                  color={'transparent'}
                  onClick={toggleProjectImportLayer}
                >
                  <MoreIcon />
                </IconButton>
              }
              placement="bottom-right"
              isOpen={isProjectImportLayerOpen}
              closeOnClickOutside={true}
              onClose={() => setIsProjectImportLayerOpen(false)}
              style={{ padding: 0 }}
            >
              <StyledProjectImportDropDown>
                <section className="project-control-item">
                  <label htmlFor="btn-import-file" className="btn-import-file">
                    <ImportIcon />
                    {t('Import Project (.json)')}

                    <input
                      type="file"
                      color="transparent"
                      id="btn-import-file"
                      accept=".json"
                      multiple={false}
                      onChange={importProject}
                    />
                  </label>
                </section>
              </StyledProjectImportDropDown>
            </Dropdown>
          </section>
        </section>
        <section className="project-contents">
          <ul className="project-list">
            <li className="project-list-item create-new" onClick={addProject}>
              <section className="btn-create-new">
                {t('Create New Project')}
              </section>
              <section className="icon-create-new">
                <AddIcon />
              </section>
            </li>
            {projectList?.map((project) => (
              <ProjectItem
                key={project.id}
                project={project}
                onDelete={removeProject}
                onChangeName={onChangeProjectName}
              />
            ))}
          </ul>
        </section>
      </section>
    </StyledProjects>
  );
};

const Projects = () => {
  return (
    <StyledProjects className="projects">
      <ProjectList />
    </StyledProjects>
  );
};
export default Projects;
