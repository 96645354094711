import { useRecoilValue } from 'recoil';

import { selectedQuickMenuAtom } from '../stores/atoms/ui';
import { QuickMenuType } from '../stores/data/config';
import StyledQuickMenuPanel from '../styles/StyledQuickMenuPanel';
import MusicTab from './MusicTab';
import ScenesTab from './ScenesTab';
import ScriptTab from './ScriptTab';
import VoicesTab from './VoicesTab';

type QuickMenuTab = {
  name: QuickMenuType;
  Component: React.FC;
};
const QUICK_MENU_TAB: QuickMenuTab[] = [
  {
    name: 'Voice',
    Component: VoicesTab,
  },
  {
    name: 'Scene',
    Component: ScenesTab,
  },
  {
    name: 'Music',
    Component: MusicTab,
  },
  {
    name: 'Script',
    Component: ScriptTab,
  },
];

const QuickMenuPanel = () => {
  const selectedQuickMenu = useRecoilValue(selectedQuickMenuAtom);
  const QuickPanel = QUICK_MENU_TAB.find(
    (tab) => tab.name === selectedQuickMenu
  )?.Component;
  return (
    <StyledQuickMenuPanel className="sp-top-panel sp-project">
      <section className="tab-content">{QuickPanel && <QuickPanel />}</section>
    </StyledQuickMenuPanel>
  );
};

export default QuickMenuPanel;
